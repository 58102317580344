import { Box, Grid, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import React from "react";
import "../../../assets/css/App.css";
import Chart from "react-apexcharts";
import SpinnerCircle from "Utils/SpinnerCircle";
import { durationChartData } from "../utils/ChartDefaultData";
import { secondsToHms } from "Utils/Common";

export const BrowserEventsCharts = ({
  stateDomains,
  stateBrowserDuration,
  stateUrls,
  browserTime,
  loadingData,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack spacing={3}>
        <Box
          className="BrowserDomainsty"
          style={{
            overflow: "hidden",
            gridTemplateColumns: loadingData && "none",
            padding: loadingData && "10px",
          }}
        >
          {loadingData ? (
            <Box textAlign={"center"} my={"10px"}>
              <SpinnerCircle />
            </Box>
          ) : (
            <SimpleGrid
              columns={{ base: 1, md: 2 }}
              mt={"5px"}
              w="100%"
              mx={"auto"}
              mb={"10px"}
              boxSizing="border-box"
            >
              <Grid item xs={8}>
                <Box>
                  <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
                    Browser Domains
                  </Text>
                  <Box h={"18rem"} overflowY={"scroll"} mb={"30px"}>
                    {stateDomains.series[0].data.length > 0 && (
                      <Chart
                        className="appBrowserBoxFillColor"
                        options={stateDomains.options}
                        series={stateDomains.series}
                        type="bar"
                        width="100%"
                        height={stateDomains.series[0].data.length * 30}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Text
                    pl={"30px"}
                    fontSize={"20px"}
                    py={"10px"}
                    width={"16rem"}
                  >
                    Active Time :
                    <Box style={{ fontSize: "14px" }}>
                      {secondsToHms(browserTime)}
                    </Box>
                  </Text>
                  {stateBrowserDuration.length > 0 && (
                    <Chart
                      className="appBrowserBoxFillColor"
                      options={durationChartData.options}
                      series={stateBrowserDuration}
                      type="radialBar"
                      width="380"
                    />
                  )}
                </Box>
                {secondsToHms(browserTime) && (
                  <Box
                    pl={"30px"}
                    fontSize={"20px"}
                    py={"10px"}
                    width={"16rem"}
                    mb={"10px"}
                  >
                    [
                    <span
                      style={{
                        fontSize: "14px",
                        margin: " 0 5px",
                      }}
                    >
                      Active Time is calculated by "Total Activity Duration" / 9
                      (hours)
                    </span>
                    ]
                  </Box>
                )}
              </Grid>
            </SimpleGrid>
          )}
        </Box>
        <Grid
          item
          xs={12}
          className=" BrowserUrlsSty"
          my={"auto"}
          w={"100%"}
          mb={"18px"}
          pb={"20px"}
        >
          {loadingData ? (
            <Text textAlign={"center"} my={"18px"}>
              <SpinnerCircle />
            </Text>
          ) : (
            <>
              <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
                Browser Urls
              </Text>
              <Box h={"14rem"} overflowY={"scroll"}>
                <Box>
                  {stateUrls.series[0].data.length > 0 && (
                    <Chart
                      className="appBrowserBoxFillColor"
                      options={stateUrls.options}
                      series={stateUrls.series}
                      type="bar"
                      width="100%"
                      height={stateUrls.series[0].data.length * 30}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
