import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorMode,
} from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";
import { fetchUserWork } from "apis/attendance";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.module.css";
import "react-image-gallery/styles/css/image-gallery.css";

export const WorkDialog = ({ isOpen, onClose, selectEvent }) => {
  const { colorMode } = useColorMode();
  const [userWorkList, setWorkList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, err } = await fetchUserWork(selectEvent);
        if (!err) {
          setLoading(false);
          setWorkList(data.data);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxH={"82vh"} overflow="hidden">
        <ModalHeader>Work List Of {`${selectEvent.email}`}</ModalHeader>
        <ModalCloseButton border={'none'}/>
        <ModalBody overflow={"hidden"}>
          <Box overflowX="auto" mb="20px" px="20px">
            <TableContainer h="90%" w="100%">
              <Table width="100%" variant="striped" colorScheme="blackAlpha" >
                <Thead color="gray" textTransform="uppercase">
                  <Tr textAlign="left">
                    <Th pe="10px" py="10px" px="10px">
                      Date
                    </Th>
                    <Th pe="10px" py="10px" px="10px">
                      Message
                    </Th>
                    <Th pe="10px" py="10px" px="10px">
                      Save At
                    </Th>
                  </Tr>
                </Thead>
                <Tbody color={colorMode==='light'?"":"gray.200"}>
                  {loading ? (
                    <Tr>
                      <Td colSpan={4} textAlign="center">
                        <SpinnerCircle />
                      </Td>
                    </Tr>
                  ) : userWorkList.length === 0 ? (
                    <Box
                      style={{ display: "flex", justifyContent: "center" }}
                      m={"10px"}
                    >
                      No Work List Found
                    </Box>
                  ) : (
                    userWorkList.map((message, index) => (
                      <Tr key={index}>
                        <Td fontSize="sm" p="10px">
                          {message.text_date}
                        </Td>
                        <Td fontSize="sm" p="10px">
                          {message.about_work}
                        </Td>
                        <Td fontSize="sm" p="10px">
                          {message.save_at}
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
