import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { SearchIcon } from "@chakra-ui/icons";

export default function SearchUser({searchValue,setSearchValue,width}) {

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  return (
    <InputGroup  width={width} borderColor="#2b6cb0" color="#2b6cb0">
      <InputLeftElement pointerEvents="none">
        <SearchIcon color="#2b6cb0" />
      </InputLeftElement>
      <Input
      className="searchInput"
        type="text"
        placeholder="Search..."
        value={searchValue}
        onChange={handleSearchChange}
      />
    </InputGroup>
  );
};
