import React from "react";
import { DataSet } from "vis-data/standalone";
import { TimelineVis } from "./TimelineEvent/TimelineVis";
import { Box, Grid, Text } from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";

const TimelineEventCharts = ({
  seriesData,
  timelintDataId,
  loadingData,
  time,
}) => {
  const {
    afkId,
    webBrowserId,
    windowUserId,
    afkTotalTimeDuration,
    notAfkTotalTimeDuration,
  } = timelintDataId;

  const options = {
    locale: "en",
    locales: {
      en: {},
    },
    start: time.start,
    end: time.end,
    // min: time.start,
    // max: time.end,
    preferZoom: true,
    stack: false,
    groupHeightMode: "fixed",
    // timeAxis: { scale: "day" },
    zoomMin: 1000 * 30,
    zoomMax: 1000 * 60 * 60 * 24 * 7,
    tooltip: {
      followMouse: true,
      overflowMethod: "cap",
    },
  };

  const groups = new DataSet()
  groups.add([
    { id: 1, content: afkId ?? "AFK" },
    { id: 2, content: webBrowserId ?? "Web Browser" },
    { id: 3, content: windowUserId ?? "Window User" },
  ]);

  const items = new DataSet();
  items.add([...seriesData]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid
        item
        xs={12}
        className="BrowserUrlsSty"
        style={{
          margin: "0px auto",
          width: "100%",
          marginBottom: "18px",
          paddingBottom: "20px",
        }}
      >
        <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
          Timeline Events
        </Text>
        <Box>
          {loadingData ? (
            <Text textAlign={"center"} my={"10px"}>
              <SpinnerCircle />
            </Text>
          ) : items && items.length > 0 ? (
            <>
              <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
                <Box fontSize={"14px"}>
                  Afk :
                  <span style={{ marginLeft: 5 }}>
                    {afkTotalTimeDuration.length !== 0
                      ? afkTotalTimeDuration
                      : "NA"}
                  </span>
                </Box>
                <Box fontSize={"14px"}>
                  Not-Afk :
                  <span style={{ marginLeft: 5 }}>
                    {notAfkTotalTimeDuration.length !== 0
                      ? notAfkTotalTimeDuration
                      : "NA"}
                  </span>
                </Box>
              </Text>
              <TimelineVis groups={groups} items={items} options={options} />;
            </>
          ) : (
            <Text textAlign={"center"} my={"10px"}>
              Timeline Events Data Not Found
            </Text>
          )}
        </Box>
      </Grid>
    </Box>
  );
};

export default TimelineEventCharts;
