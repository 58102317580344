import React from "react";

// Chakra imports
import { Flex, Image, useColorMode } from "@chakra-ui/react";
import lightLogo from "assets/img/logo/CarbonTrackLogo.svg";
import darkLogo from 'assets/img/logo/CarbonTrackDarkLogo.svg'
// Custom components
// import { HorizonLogo } from "components/icons/Icons";
import { HSeparator } from "Utils/Separator";

export function SidebarBrand() {
  const { colorMode } = useColorMode()

  //   Chakra color mode
  // let logoColor = useColorModeValue("navy.700", "white");

  return (
    <Flex align="center" direction="column">
      {/* <HorizonLogo h="26px" w="175px" my="32px" color={logoColor} /> */}
      <Image h="40px" w="200px" my="32px" src={colorMode==='light'?lightLogo:darkLogo } alt="CarbonTrack Logo" />
      <HSeparator mb="20px" />
    </Flex>
  );
}

export default SidebarBrand;
