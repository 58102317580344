import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getToken, getUser, removeUserSession } from "./Common";

// handle the private routes
function PrivateRouteSupervisor({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        getToken() && !getUser().isadmin ? (
          <Component {...props} />
        ) : (
          <>
            {removeUserSession()}
            <Redirect from="/" to="/auth" />
          </>
        )
      }
    />
  );
}

export default PrivateRouteSupervisor;
