import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { removeUserSession } from "Utils/Common";
import { getUser } from "Utils/Common";
import SpinnerCircle from "Utils/SpinnerCircle";
import { getSessionForImage } from "apis/session";
import { getSupervisorUser } from "apis/supervisor";
import { getExtensionUser } from "apis/user";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export const SessionDialog = ({ isOpen, onClose, selectEvent }) => {
  const { colorMode } = useColorMode();
  const [images, setImages] = useState(null);

  const [interval, setInterval] = useState(3600000 * 0.9);
  const [intervalToSend, setIntervalToSend] = useState(3600000 * 0.9 + 1);
  const [intervalValue, setIntervalValue] = useState(1);
  //   let [fullScreen, setFullScreen] = useState({ open: false, img: 0 });
  const [startTime, setStartTime] = useState(
    new Date(selectEvent.start_session)
  );
  const [endTime, setEndTime] = useState(new Date(selectEvent.end_session));

  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState(selectEvent.email);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (getUser().isadmin) {
          response = await getExtensionUser();
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj);
        }

        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    getSessionImage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchDataAgain=()=>{
    if (startTime && endTime && userName && intervalToSend) {
      getSessionImage()
    }
  }

  const getSessionImage = async () => {
    if (startTime && endTime && userName && intervalToSend) {
      setLoading(true);
      try {
      let obj = {
        start: moment(startTime).utc().format(),
        end: moment(endTime).utc().format(),
        user: userName,
        interval: intervalToSend,
      };

        const { data, err } = await getSessionForImage(obj);

        if (!Array.isArray(data)) {
          setLoading(false);
        }
        if (data && data.length > 0) {
          let sortedData = data.sort(
            (a, b) =>
              new Date(a.created_at).getTime() -
              new Date(b.created_at).getTime()
          );
          setLoading(false);
          if (sortedData.length > 0) {
            const actualImages = sortedData.map((item, index) => ({
              original: `/api/files/image/${item.image_name}`,
              thumbnail: `/api/files/image/${item.image_name}`,
              description: `${item.created_at}`,
            }));
            setImages(actualImages);
          } else {
            setImages([
              {
                original:
                  "https://arnowa.s3.us-west-002.backblazeb2.com/ce5d04e1-3b53-4d64-abac-5ad3da739826.png",
                thumbnail:
                  "https://arnowa.s3.us-west-002.backblazeb2.com/ce5d04e1-3b53-4d64-abac-5ad3da739826.png",
              },
            ]);
          }
        } else {
          setImages(null);
          console.log("Cannot get session", err);
        }
      } catch (error) {
        setLoading(false);
        console.log("getSession func try error");
        // console.error(error);
      }
    }
  };



  const handleInterval = (e) => {
    setIntervalValue(e.target.value);
  };
  const onBlurHandleInterval=()=>{
    if (interval) {
      setIntervalToSend(intervalValue * interval);
    }
  }

  const handleChangeSelect = (e) => {
    setInterval(e.target.value);
    if (interval) {
      setIntervalToSend(e.target.value * intervalValue);
    }
  };

  const onClick = (e) => {
    images.map((item, index) => {
      if (item.original === e.target.src) {
        // setFullScreen({ open: true, img: index });
      }
      return item;
    });
  };
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="3xl"
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Sessions
          {images && images.length > 0
            ? ` Total Images - ${images.length}`
            : ""}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing={3}>
            <Flex flexDirection={"row"} columnGap={"20px"}>
              <Select
                variant="filled"
                value={userName}
                placeholder="Select User"
                onChange={(e) => setUserName(e.target.value)}
              >
                {users.length > 0 &&
                  users.map((item, index) => (
                    <option
                      key={index}
                      value={getUser().isadmin ? item.email : item.useremail}
                    >
                      {getUser().isadmin
                        ? `${item.firstname}  ${item.lastname}`
                        : `${item.name}`}
                    </option>
                  ))}
              </Select>
              <Select
                variant="filled"
                value={interval}
                onChange={handleChangeSelect}
              >
                <option value={60000 * 0.9}>Minute</option>
                <option value={3600000 * 0.9}>Hour</option>
                <option value={86400000 * 0.9}>Day</option>
              </Select>
              <Input
                variant="filled"
                placeholder="Time"
                type="Number"
                value={intervalValue}
                onChange={handleInterval}
                onBlur={onBlurHandleInterval}
                className={
                  colorMode === "light"
                    ? "datepicker-input"
                    : "custom-datepicker-input"
                }
              />
            </Flex>
            <Flex
              flexDirection={"row"}
              columnGap={"20px"}
              justifyContent={"space-between"}
            >
              <Box display={"flex"} gap={3} marginLeft={"10px"}>
                <Text>From</Text>
                <ReactDatePicker
                  showTimeSelect
                  showIcon={true}
                  selected={startTime}
                  onChange={(value) => {
                    setStartTime(value);
                  }}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  className={
                    colorMode === "light"
                      ? "datepicker-input"
                      : "custom-datepicker-input"
                  }
                />
              </Box>
              <Box display={"flex"} gap={3}>
                <Text>To</Text>
                <ReactDatePicker
                  showIcon={true}
                  showTimeSelect
                  selected={endTime}
                  onChange={(value) => {
                    setEndTime(value);
                  }}
                  dateFormat="MM/dd/yyyy h:mm aa"
                  className={
                    colorMode === "light"
                      ? "datepicker-input"
                      : "custom-datepicker-input"
                  }
                />
              </Box>
              <Box>
                <Button colorScheme="blue" variant="outline" onClick={fetchDataAgain}>
                Fetch Details
                </Button>
              </Box>
            </Flex>
          </Stack>
          {loading ? (
            <Text textAlign={"center"} m={"10px"}>
              <SpinnerCircle />
            </Text>
          ) : !images ? (
            <Box
              style={{ display: "flex", justifyContent: "center" }}
              m={"10px"}
              minH={'65vh'}
           >
              No Image Found
            </Box>
          ) : (
            <Box m={"10px"} minH={'65vh'}>
              <ImageGallery
                style={{ overflow: "hidden" }}
                slideDuration={100}
                slideInterval={2000}
                items={images}
                onClick={onClick}
                originalClass="imageSlider"
              />
            </Box>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
