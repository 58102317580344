import moment from "moment";

// Require the package.json file
const packageJson = require('../../package.json');

// access the version property
export const version = packageJson.version;

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem("user");
  if (userStr) return JSON.parse(userStr);
  else return null;
};

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem("token") || null;
};

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
};

// set the token and user from the session storage
export const setUserSession = (token, user) => {
  const userStr = localStorage.getItem("user");
  const tokenStr = localStorage.getItem("token");
  if (!userStr && !tokenStr) {
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  }
};

//convert date to string 
export const dateToString=(now)=>{
return moment(now).add(1, "days").utc().format("MM/DD/YYYY")
}


//return diff b/w min
export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  let timeInHM = convertMsToHM(diffInMs);
  return timeInHM;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

//convert ms to hm
export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}


//convert seconds to hms
export function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

//convert timestamp to date format DD/MM/YYYY
export function convertTimestampToDateFormat(timestamp) {
  const dateObject = new Date(timestamp);
  
  const day = String(dateObject.getDate()).padStart(2, '0');
  const month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
  const year = dateObject.getFullYear();

  const formattedDate = `${day}-${month}-${year}`;
  return formattedDate;
}
