import { Box, useColorModeValue, useDisclosure } from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";
import React, { useEffect, useState } from "react";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import '../../assets/css/BigCalender.css'
import enUS from "date-fns/locale/en-US";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { SessionDialog } from "./SessionDialog";
import { eventAttendance } from "apis/attendance";
import { WorkDialog } from "./WorkDialog";
import { convertTimestampToDateFormat } from "Utils/Common";
const locales = {
  "en-US": enUS,
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export const AttendanceCalender = ({
  userName,
  selectedButton,
}) => {
  const [events, setEvents] = useState([]);
  const [selectEvent, setSelectEvent] = useState({});
  const [spinnerState, setSpinnerState] = useState(false);
  const borderColor = useColorModeValue("gray.100", "whiteAlpha.100");
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userName.length > 0) {
          setSpinnerState(true);
          const { data, err } = await eventAttendance({ email: userName });

          if (!err && data.length > 0) {
            const formattedEvents = data.map((item) => ({
              title: item.email,
              start: item.start_session,
              end: item.end_session,
            }));

            setEvents(formattedEvents);
          } else {
            setEvents([]);
          }
        }
      } catch (error) {
        console.log("/api/attendance/email cannot get data", error);
      } finally {
        setSpinnerState(false);
      }
    };

    fetchData();
  }, [userName]);

  const eventStyleGetter = (event, start, end, isSelected) => ({
    style: {
      backgroundColor: borderColor ,
    },
  });

  const onEventClick = (event) => {
    if (selectedButton === "images") {
      const selectedEvent = {
        email: event.title,
        end_session: event.end,
        start_session: event.start,
      };
      onOpen();
      setSelectEvent(selectedEvent);
    } else if (selectedButton === "work") {
      const date =convertTimestampToDateFormat(event.start)
      const selectedEvent = {
        user: event.title,
        date:date
      };
      onOpen();
      setSelectEvent(selectedEvent);
    }
  };

  const eventStyle = {
    backgroundColor: '#3174ad',
    color: 'white',
    borderRadius: '3px',
  };

  return (
    <>
      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          padding: "18px 0px",
        }}
      >
        {spinnerState ? (
          <SpinnerCircle />
        ) : (
          <Calendar
            className="calendarStyle"
            eventStyle={eventStyle}
            localizer={localizer}
            events={events}
            startAccessor={(event) => new Date(event.start)}
            endAccessor={(event) => new Date(event.end)}
            eventPropGetter={eventStyleGetter}
            onSelectEvent={onEventClick}
          />
        )}
      </Box>
      {isOpen &&
        (selectedButton === "images" ? (
          <SessionDialog
            isOpen={isOpen}
            onClose={onClose}
            selectEvent={selectEvent}
          />
        ) : (
          <WorkDialog
            isOpen={isOpen}
            onClose={onClose}
            selectEvent={selectEvent}
          />
        ))}
    </>
  );
};
