import { secondsToHms } from "Utils/Common";
import { findHostNameFromUrl } from "./commnon";

export const title = {
  options: {
    chart: {
      type: "bar",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#3F3F3F"],
        fontWeight: 200,
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 1,
      dropShadow: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
      yaxis: {
        show: false,
      },
    },
  },
  series: [
    {
      data: [],
    },
  ],
};
export const appEvents = {
  options: {
    chart: {
      type: "bar",
    },
    dataLabels: {
      enabled: true,
      textAnchor: "start",
      style: {
        colors: ["#3F3F3F"],
        fontWeight: 200,
      },
      formatter: function (val, opt) {
        return opt.w.globals.labels[opt.dataPointIndex];
      },
      offsetX: 0,
      dropShadow: {
        enabled: false,
      },
    },
    yaxis: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
  },
  series: [
    {
      data: [],
    },
  ],
};
export const durationChartData = {
  options: {
    plotOptions: {
      radialBar: {
        startAngle: 0,
        endAngle: 360,
        hollow: {
          margin: 0,
          size: "70%",
          background: "#fff",
          image: undefined,
          imageOffsetX: 0,
          imageOffsetY: 0,
          position: "front",
          dropShadow: {
            enabled: true,
            top: 3,
            left: 0,
            blur: 4,
            opacity: 0.24,
          },
        },
        track: {
          background: "#fff",
          strokeWidth: "67%",
          margin: 0, // margin is in pixels
          dropShadow: {
            enabled: true,
            top: -3,
            left: 0,
            blur: 4,
            opacity: 0.35,
          },
        },

        dataLabels: {
          showOn: "always",
          name: {
            offsetY: -20,
            show: true,
            color: "#3F3F3F",
            fontSize: "30px",
          },
          value: {
            formatter: function (val) {
              return `${val}%`;
            },
            color: "#111",
            fontSize: "30px",
            show: true,
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: ["#ABE5A1"],
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: ["Time"],
  },
};

export const options = [
  {
    label: "App Events",
    value: 1,
  },
  {
    label: "Browser Events",
    value: 2,
  },
  {
    label: "Timeline Events",
    value: 3,
  },
];

function timestampDutation(second, time) {
  var t = new Date(time);
  t.setSeconds(t.getSeconds() + second);
  return t;
}
export const getAkfData = async (dataObject) => {
  const { awWatcherAfk } = dataObject;

  if (awWatcherAfk.length === 0) {
    return { afkdata: [], afkTime: 0, notAfkTime: 0 };
  }

  let afkTime = 0;
  let notAfkTime = 0;

  const afkdata = awWatcherAfk.map((item, index) => {
    const status = item.data[0].status;
    const fillColor = status === "afk" ? "#eee" : "#77ff66";
    const duration = item.duration;
    const timestamp = new Date(item.timestamp).getTime();
    const durationTimestamp = timestampDutation(
      duration,
      item.timestamp
    ).getTime();

    if (status === "afk") {
      afkTime += duration;
    } else if (status === "not-afk") {
      notAfkTime += duration;
    }

    const tooltipTimelineEvent = {
      y: [timestamp, durationTimestamp],
      tooltipdata: {
        status,
        duration: secondsToHms(duration),
      },
    };

    const style = `background-color: ${fillColor}; border-color: ${fillColor}; font-size: 10px; font-weight: bold;`;
    const tooltipAfk = aktTooltip(tooltipTimelineEvent);

    return {
      id: index + 1,
      group: 1,
      content: status,
      start: timestamp,
      end: durationTimestamp,
      title: tooltipAfk,
      style,
    };
  });

  return { afkdata, afkTime, notAfkTime };
};

export const getWebChromeData = async (dataObject, colorData, afklength) => {
  const { awWatcherWeb } = dataObject;

  if (awWatcherWeb.length === 0) {
    return [];
  }

  const webdata = awWatcherWeb.map((item, index) => {
    const timestamp = new Date(item.timestamp).getTime();
    const durationTimestamp = timestampDutation(
      item.duration,
      item.timestamp
    ).getTime();
    const url = findHostNameFromUrl(item.data[0].url);
    const appColor =
      colorData.web.find((ele) => ele.url === url)?.color || "defaultColor";

    const tooltipTimelineEvent = {
      y: [timestamp, durationTimestamp],
      tooltipdata: {
        audible: item.data[0].audible,
        incognito: item.data[0].incognito,
        tabCount: item.data[0].tabCount,
        title: item.data[0].title,
        url: item.data[0].url,
        duration: secondsToHms(item.duration),
      },
    };

    const style = `background-color: ${appColor}; border-color: ${appColor}; font-size: 10px; font-weight: bold;`;
    const tooltipWeb = webBrowserTooltip(tooltipTimelineEvent);

    return {
      id: afklength + (index + 1),
      group: 2,
      content: item.data[0].title,
      start: timestamp,
      end: durationTimestamp,
      title: tooltipWeb,
      style,
    };
  });

  return webdata;
};

export const getWindowUserData = async (
  dataObject,
  colorData,
  afkWeblength
) => {
  const { awWatcherWindow } = dataObject;

  if (awWatcherWindow.length === 0) {
    return [];
  }

  const windowdata = awWatcherWindow.map((item, index) => {
    const timestamp = new Date(item.timestamp).getTime();
    const durationTimestamp = timestampDutation(
      item.duration,
      item.timestamp
    ).getTime();
    const tooltipTimelineEvent = {
      y: [timestamp, durationTimestamp],
      tooltipdata: {
        app: item.data[0].app,
        title: item.data[0].title,
        duration: secondsToHms(item.duration),
      },
    };

    const appColor =
      colorData.window.find((ele) => ele.app === item.data[0].app)?.color ||
      "defaultColor";
    const style = `background-color: ${appColor}; border-color: ${appColor}; font-size: 10px; font-weight: bold;`;
    const tooltipWindowUser = windowUseTooltip(tooltipTimelineEvent);

    return {
      id: afkWeblength + (index + 1),
      group: 3,
      content: item.data[0].app,
      start: timestamp,
      end: durationTimestamp,
      title: tooltipWindowUser,
      style,
    };
  });

  return windowdata;
};

function windowUseTooltip(data) {
  const { y, tooltipdata } = data;
  const { app, duration, title } = tooltipdata;
  var table = "<table >";
  table =
    table +
    "<tr><td style='font-weight: 600'>" +
    "Start :  " +
    "</td><td>" +
    new Date(y[0]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Stop :  " +
    "</td><td>" +
    new Date(y[1]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Duration :  " +
    "</td><td >" +
    duration +
    "<tr><td style='font-weight: 600'>" +
    "App :  " +
    "</td><td>" +
    app +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Title :  " +
    "</td><td>" +
    title +
    "</td></tr>";

  table = table + "</table>";

  return "<div style='margin: 5px;'>" + table + "</div>";
}

function webBrowserTooltip(data) {
  const { y, tooltipdata } = data;
  const { title, url, duration } = tooltipdata;
  var table = "<table >";
  table =
    table +
    "<tr><td style='font-weight: 600'>" +
    "Start :  " +
    "</td><td>" +
    new Date(y[0]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Stop :  " +
    "</td><td>" +
    new Date(y[1]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Duration :  " +
    "</td><td>" +
    duration +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Title :  " +
    "</td><td>" +
    title +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Url :  " +
    "</td><td>" +
    url +
    "</td></tr>";

  table = table + "</table>";

  return "<div style='margin: 5px;'>" + table + "</div>";
}

function aktTooltip(data) {
  const { y, tooltipdata } = data;
  const { status, duration } = tooltipdata;
  var table = "<table >";
  table =
    table +
    "<tr><td style='font-weight: 600'>" +
    "Start :  " +
    "</td><td>" +
    new Date(y[0]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Stop :  " +
    "</td><td>" +
    new Date(y[1]).toUTCString() +
    "</td></tr>" +
    "<tr><td style='font-weight: 600'>" +
    "Duration :  " +
    "</td><td>" +
    duration +
    "</td></tr>" +
    "<tr><td >" +
    "Data :  " +
    "</td><td>" +
    `{ "Status" : "${status}"}` +
    "</td></tr>";

  table = table + "</table>";

  return "<div style='margin: 5px; overflow: visible;'>" + table + "</div>";
}
