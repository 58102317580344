import { Box, Flex, Select, Text, useColorMode } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getUser, removeUserSession, dateToString } from "Utils/Common";
import { getExtensionUser } from "apis/user";
import { AttendanceDate } from "./AttendanceDate";
import { AttendanceMonth } from "./AttendanceMonth";
import { AttendanceCalender } from "./AttendanceCalender";
import moment from "moment";
import "../../assets/css/App.css";
import { getSupervisorUser } from "apis/supervisor";
import DownloadReportFileModal from "./DownloadReportFileModal";

const options = [
  { label: "Get Attendance on Date", value: 1 },
  { label: "Get Attendance on Calender", value: 2 },
  { label: "Get Month Attendance", value: 3 },
];

export const AttendanceDashboard = () => {
  const toggleOptions = [
    { label: "Images", value: "images" },
    { label: "Work", value: "work" },
  ];

  const { colorMode } = useColorMode();
  const [switchToPage, setSwitchToPage] = useState(1);
  const [date, setDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [dateMonth, setDateMonth] = useState({
    stopDate: moment(new Date()).utc().format("MM/DD/YYYY"),
    startDate: moment(new Date())
      .subtract(5, "days")
      .utc()
      .format("MM/DD/YYYY"),
  });
  const [selectedButton, setSelectedButton] = useState("images");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (getUser().isadmin) {
          response = await getExtensionUser();
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj);
        }

        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleStart = (value) => {
    const now = new Date(value);
    const dateString = dateToString(now);
    setDateMonth((prev) => ({ ...prev, startDate: dateString }));
  };

  const handleEnd = (value) => {
    const now = new Date(value);
    const dateString = dateToString(now);
    setDateMonth((prev) => ({ ...prev, stopDate: dateString }));
  };

  const handleSwitchChange = (value) => {
    setSelectedButton(value);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card
        direction="column"
        w="100%"
        px="0px"
        backgroundColor={colorMode === "light" ? "" : "#101c44"}
      >
        <Flex
          px="20px"
          py="10px"
          gap={"20px"}
          mb={4}
          flexDirection={{ base: "column", md: "row" }}
        >
          <Box w={"auto"}>
            <Select
              variant="filled"
              value={switchToPage}
              onChange={(e) => setSwitchToPage(Number(e.target.value))}
            >
              {options.map((item, index) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </Box>
          {switchToPage === 1 && (
            <Flex justifyContent={'space-between'} width={'100%'}>
              <Box>
                <DatePicker
                  selected={date}
                  onChange={(value) => setDate(value)}
                  showIcon={true}
                  dateFormat={"MM/dd/yyyy"}
                  className={
                    colorMode === "light"
                      ? "datepicker-input"
                      : "custom-datepicker-input"
                  }
                />
              </Box>
              <Box>
                <DownloadReportFileModal />
              </Box>
            </Flex>
          )}
          {switchToPage === 2 && (
            <Flex className="attendanceCalenderFlex">
              <Box
                className="switchDataType"
                borderColor={"gray.200"}
                height={"100%"}
              >
                {toggleOptions.map((item) => (
                  <Box
                    className="switchType"
                    key={item.value}
                    bg={
                      colorMode === "light"
                        ? selectedButton === item.value
                          ? "gray.200"
                          : "white"
                        : selectedButton === item.value
                        ? "#1a254b"
                        : "transparent"
                    }
                    cursor="pointer"
                    onClick={() => handleSwitchChange(item.value)}
                  >
                    <Text color={colorMode === "light" ? "black" : "white"}>
                      {item.label}
                    </Text>
                  </Box>
                ))}
              </Box>

              <Select
                width={"100%"}
                variant="filled"
                value={userName}
                placeholder="Select User"
                onChange={(e) => setUserName(e.target.value)}
              >
                {users.map((item, index) => (
                  <option
                    key={index}
                    value={getUser().isadmin ? item.email : item.useremail}
                  >
                    {`${item.firstname || item.name} ${item.lastname || ""}`}
                  </option>
                ))}
              </Select>
            </Flex>
          )}
          {switchToPage === 3 && (
            <Box display={{ base: "block", md: "flex" }} gap="20px">
              {["From", "To"].map((key) => (
                <Box
                  key={key}
                  display={{ base: "block", md: "flex" }}
                  gap="10px"
                  alignItems={"center"}
                >
                  <Text>{key === "From" ? "From" : "To"}</Text>
                  <DatePicker
                    className={
                      colorMode === "light"
                        ? "datepicker-input"
                        : "custom-datepicker-input"
                    }
                    showIcon={true}
                    value={
                      key === "From" ? dateMonth.startDate : dateMonth.stopDate
                    }
                    onChange={
                      key === "From"
                        ? (value) => handleStart(value)
                        : (value) => handleEnd(value)
                    }
                  />
                </Box>
              ))}
            </Box>
          )}
        </Flex>
        {switchToPage === 1 && <AttendanceDate date={date} />}
        {switchToPage === 2 && (
          <AttendanceCalender
            userName={userName}
            selectedButton={selectedButton}
          />
        )}
        {switchToPage === 3 && <AttendanceMonth dateMonth={dateMonth} />}
      </Card>
    </Box>
  );
};

// component for a Box with DatePicker
export const BoxWithDatePicker = ({ label, value, onChange, colorMode }) => (
  <Box>
    <Text>{label}</Text>
    <DatePicker
      className={
        colorMode === "light" ? "datepicker-input" : "custom-datepicker-input"
      }
      showIcon={true}
      value={value}
      onChange={(value) => onChange(value)}
    />
  </Box>
);
