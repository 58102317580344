import React, { useEffect, useState } from "react";
import {
  Box,
  Flex,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorMode,
} from "@chakra-ui/react";
import { getUser } from "Utils/Common";
import { removeUserSession } from "Utils/Common";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.module.css";
import { UserSessions } from "./userSessions";
import { KeylogData } from "./keylogData";
import { SessionImages } from "./SessionImages";
import { IndexActivityWatcher } from "./ActivityWatcher/IndexActivityWatcher";
import Card from "components/card/Card";
import UserReports from "./UserReport/UserReports";
import { getExtensionUser } from "apis/user";
import { getSupervisorUser } from "apis/supervisor";

export const UserIndex = () => {
  const { colorMode } = useColorMode();
  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const authToken = url.searchParams.get("authToken");

  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");

  const [userSessions, setUserSessions] = useState("");
  const [userImages, setUserImages] = useState("");
  const [activityTime, setActivityTime] = useState("");
  const [browserActivityTime, setBrowserActivityTime] = useState("");
  const [time, setTime] = useState({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        if (getUser().isadmin) {
          response = await getExtensionUser()
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj)
        }
        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (authToken) {
      //fetch data when login use auth token 
      //for capture user activity only
      fetchData();
      const currentDate = new Date();
              setTime({
                start: new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate(),
                  9,
                  0,
                  0,
                  0
                ),
                end: new Date(
                  currentDate.getFullYear(),
                  currentDate.getMonth(),
                  currentDate.getDate(),
                  19,
                  0,
                  0,
                  0
                ),
              });
    } else {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUserSelectChange = (e) => {
    setUserName(e.target.value);
    setUserSessions("");
    setUserImages("");
    setActivityTime("");
    setBrowserActivityTime("");
  };

  const handleDateChange = (key, value) => {
    setTime({
      ...time,
      [key]: value,
    });
  };

  return (
    <Card
      backgroundColor={colorMode === "light" ? "#e9e9e926" : "#101c44"}
      pt={{ base: "130px", md: "80px", xl: "80px" }}
    >
      <Stack spacing={5} >
        <Box>
          <Flex
            px="20px"
            py="10px"
            m={"auto"}
            justifyContent={"space-between"}
            gap={"20px"}
            flexDirection={{ base: "column", md: "row" }}
            backgroundColor={colorMode === "light" ? "white" : ""}
            borderRadius={"10px"}
          >
            <Select
              variant="filled"
              value={userName}
              onChange={handleUserSelectChange}
              placeholder="Select user"
            >
              {users.map((item, index) => (
                <option
                  key={index}
                  value={getUser().isadmin ? item.email : item.useremail}
                >
                  {getUser().isadmin
                    ? `${item.firstname} ${item.lastname}`
                    : item.name}
                </option>
              ))}
            </Select>
            <Box display={{ base: "block", md: "flex" }} gap="20px">
              {["start", "end"].map((key) => (
                <Box
                  key={key}
                  display={{ base: "block", md: "flex" }}
                  gap="20px"
                  alignItems={"center"}
                >
                  <Text>{key === "start" ? "From" : "To"}</Text>
                  <ReactDatePicker
                    showTimeSelect
                    showIcon={true}
                    selected={time[key]}
                    onChange={(value) => handleDateChange(key, value)}
                    dateFormat="MM/dd/yyyy h:mm aa"
                    className={
                      colorMode === "light"
                        ? "DatePicker-border"
                        : "custom-datepicker-input"
                    }
                  />
                </Box>
              ))}
            </Box>
          </Flex>
        </Box>
        <UserReports
          userSessions={userSessions}
          userImages={userImages}
          activityTime={activityTime}
          browserActivityTime={browserActivityTime}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Stack spacing={3}>
            <SimpleGrid columns={{ base: 1, md: 2 }} gap="20px">
              <UserSessions
                userName={userName}
                time={time}
                setUserSessions={setUserSessions}
              />
              <SessionImages
                userName={userName}
                time={time}
                setUserImages={setUserImages}
              />
            </SimpleGrid>
            <IndexActivityWatcher
              userName={userName}
              time={time}
              setActivityTime={setActivityTime}
              setBrowserActivityTime={setBrowserActivityTime}
            />
            <KeylogData userName={userName} time={time} />
          </Stack>
        </Box>
      </Stack>
    </Card>
  );
};
