export function getDifferenceInMinutes(date1, date2) {
  const diffInMs = Math.abs(date2 - date1);
  let timeInHM = convertMsToHM(diffInMs);
  return timeInHM;
}

export function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}

export function convertMsToHM(milliseconds) {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  // 👇️ if seconds are greater than 30, round minutes up (optional)
  minutes = seconds >= 30 ? minutes + 1 : minutes;

  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}`;
}


// Assign Color for Timeline Data 
export const groupDataAndColorAssign = (web, window) => {
  const colorsForWindow = ["#88ccff", "#ef9a9a", "#6aa7fe", "#ffe082"];
  const colorsForWib = ["#90caf9", "#00aaff", "#6aa7fe", "#90caf9", "#ffe082"];
  let awWatcherWebGroup = [];
  let awWatcherWindowGroup = [];

  // eslint-disable-next-line array-callback-return
  window.map((item) => {
    awWatcherWindowGroup.push(item.data[0].app);
  });
  // eslint-disable-next-line array-callback-return
  web.map((item) => {
    awWatcherWebGroup.push(item.data[0].url);
  });

  //getting host name from urls
  const urlHostname = [...new Set(awWatcherWebGroup)].map((url, index) => {
    return findHostNameFromUrl(url);
  });

  // assign Color for urls
  const uniqueWebGroup = [...new Set(urlHostname)].map((hostname, index) => {
    return {
      url: hostname,
      color: colorsForWib[index % colorsForWib.length],
    };
  });

  // Unique App value and assign color
  const uniqueWindowGroup = [...new Set(awWatcherWindowGroup)].map(
    (app, index) => {
      return { app, color: colorsForWindow[index % colorsForWindow.length] };
    }
  );
  let data = { window: uniqueWindowGroup, web: uniqueWebGroup };
  return data;
};

export function findHostNameFromUrl(url) {
  let hostname;
  if (url.startsWith("chrome://")) {
    hostname = url.substring(9);
  } else if (url) {
    const urlObj = new URL(url);
    hostname = urlObj.hostname + (urlObj.port ? `:${urlObj.port}` : "");
  } else {
    hostname = "";
  }
  return hostname;
}

export const toCamelCase=(str)=> {
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
    return index === 0 ? word.toUpperCase() : word.toUpperCase();
  }).replace(/\s+/g,' ');
}