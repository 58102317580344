// Chakra Imports
import { Button, Icon, useColorMode } from "@chakra-ui/react";
// Custom Icons
import { IoMdMoon, IoMdSunny } from "react-icons/io";
import React from "react";

export default function ThemeEditorMode() {
  const { colorMode, toggleColorMode } = useColorMode();
  let bgButton = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";

  return (
    <Button
      p="0px"
      bg={bgButton}
      variant="no-effects"
      border="1px solid"
      borderColor="#6A53FF"
      borderRadius="50%"
      onClick={toggleColorMode}
      align="center"
      justify="center"
      marginRight={"2px"}
      size="sm"
    >
      <Icon
        h="15px"
        w="15px"
        color="white"
        as={colorMode === "light" ? IoMdMoon : IoMdSunny}
      />
    </Button>
  );
}
