import {
  Box,
  // Button,
  Flex,
  Input,
  Select,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";
import { getSessionForImage } from "apis/session";
import Card from "components/card/Card";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.module.css";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export const SessionImages = ({ userName, time, setUserImages }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  // const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [images, setImages] = useState(null);

  const [interval, setInterval] = useState("");
  const [intervalToSend, setIntervalToSend] = useState("");
  const [intervalValue, setIntervalValue] = useState("");
  //   let [fullScreen, setFullScreen] = useState({ open: false, img: 0 });

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, userName, intervalToSend]);

  const getImages = async () => {
    if (time.start && time.end && userName && intervalToSend) {
      setLoading(true);
      const obj = {
        start: moment(time.start).utc().format(),
        end: moment(time.end).utc().format(),
        user: userName,
        interval: intervalToSend,
      };
      try {
        const { data, err } = await getSessionForImage(obj);
        if (!err) {
          setLoading(false);
            if (data.length > 0) {
              const actualImages = data.map((item, index) => ({
                original: `/api/files/image/${item.image_name}`,
                thumbnail: `/api/files/image/${item.image_name}`,
                description: `${item.created_at}`,
              }));
              setImages(actualImages);
              setUserImages(actualImages);

            } else {
              setImages([
                {
                  original:
                    "https://arnowa.s3.us-west-002.backblazeb2.com/ce5d04e1-3b53-4d64-abac-5ad3da739826.png",
                  thumbnail:
                    "https://arnowa.s3.us-west-002.backblazeb2.com/ce5d04e1-3b53-4d64-abac-5ad3da739826.png",
                },
              ]);
            }
        } else {
          setLoading(false);
          console.error("Error in getSessionForImage:", err);
        }
      } catch (error) {
        setLoading(false);
        console.error("In handleSubmit func /api/filesname try error", error);
      }
    }
  };

  const handleInterval = (e) => {
    setIntervalValue(e.target.value);
  };

  const handleOnBlurInterval=()=>{
    if (interval) {
      setIntervalToSend(intervalValue * interval);
    }
  }

  const handleChangeSelect = (e) => {
    setInterval(e.target.value);
    if (interval) {
      setIntervalToSend(e.target.value * intervalValue);
    }
  };

  const onClick = (e) => {
    images.map((item, index) => {
      if (item.original === e.target.src) {
        // setFullScreen({ open: true, img: index });
      }
      return item;
    });
  };
  return (
    <Card
      direction="column"
      w="100%"
      p="10px"
    >
      <Flex align="center" w="100%" px="20px" py="10px">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Sessions
        </Text>
      </Flex>
      <Stack spacing={3} marginBottom={"20px"}>
        <Flex flexDirection={"row"} columnGap={"20px"}>
          <Select
            variant="filled"
            value={interval}
            onChange={handleChangeSelect}
            placeholder="Select Interval"
          >
            <option value={60000 * 0.9}>Minute</option>
            <option value={3600000 * 0.9}>Hour</option>
            <option value={86400000 * 0.9}>Day</option>
          </Select>
          <Input
            variant="filled"
            placeholder="Time"
            type="Number"
            value={intervalValue}
            onBlur={handleOnBlurInterval}
            onChange={handleInterval}
          />
        </Flex>
      </Stack>
      {loading ? (
        <Box textAlign={"center"} m={"25px"}>
          <SpinnerCircle />
        </Box>
      ) : (
        !images && (
          <Box style={{ display: "flex", justifyContent: "center" }} m={"25px"}>
            <Text>No Images Found</Text>
          </Box>
        )
      )}
      {images && (
        <Box m={"10px"} mt={"25px"}>
          <ImageGallery
            style={{ overflow: "hidden" }}
            slideDuration={100}
            slideInterval={2000}
            items={images}
            onClick={onClick}
            originalClass="imageSlider"
          />
        </Box>
      )}
    </Card>
  );
};
