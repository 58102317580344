import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * Get user session verified token
 * @function
 * @returns {object} - object with data and error
 */
export const getUserSession = async () => {
  const token = getToken();
  if (!token) {
    return {
      data: [],
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.get(`/api/verifyToken?token=${token}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * sign in user
 * @function
 * @param {object} obj - credentials object
 * @returns {object} - object data and error
 */
export const signInUser = async (obj) => {
  try {
    const response = await axios.post(`/api/signin`, obj);
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};


/**
 * Get verified user from token
 * @function
 * @returns {object} - object with data and error
 */
export const getUserFromToken = async (token) => {
  try {
    const response = await axios.get(`/api/verifyToken?token=${token}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};