import { Select } from "@chakra-ui/react";
import { removeUserSession } from "Utils/Common";
import { getUsers } from "apis/user";
import React, { useEffect, useState } from "react";

export default function SelectSupervisor( { supervisorOption,setSupervisorOption}) {
  const [supervisors, setSupervisors] = useState([]);

useEffect(() => {

  async function fetchUserData() {
    try {
      const { data, err } = await getUsers()

      if (!err) {
        if (data.length > 0) {
          let typeArray = data.map((item) => ({
            type: "Supervisor",
            email: item.email,
            lastname: item.lastname,
            firstname: item.firstname,
          }));
          setSupervisors(typeArray);
        }
      } else {
        removeUserSession();
      }
    } catch (error) {
      console.error("unexpected error:", error);
    }
  }

  if (supervisors.length === 0) {
    fetchUserData();
  }
}, [supervisors]);

  const handleChange = (e) => {
    setSupervisorOption(e.target.value);
  };

  return (
    <>
      <Select
        w="35%"
        borderColor="#2b6cb0"
        borderRadius="15px"
        color="#2b6cb0"
        fontWeight="bold"
        onChange={handleChange}
        value={supervisorOption}
      >
        <option value="" disabled>Select Supervisor</option>
        {supervisors.map((item, index) => (
          <option key={index} value={item.email} >
            {`${item.firstname}  ${item.lastname}`}
          </option>
        ))}
      </Select>
    </>
  );
}
