import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * User Attendance
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const fetchUserAttendance = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.post("/api/attendance", {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Attendance event
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const eventAttendance = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.post("/api/attendance/email", {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * User work
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const fetchUserWork = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.post("/api/userWork", {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * User work
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const fetchWorkWithRange = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.get("/api/userWork", {
      headers: { Authorization: `Bearer ${token}` },
      params: obj,
    });
    
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};
