import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * Get user
 * @function
 * @return {object}
 */
export const getUsers = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.get(`/api/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Get extension user
 * @function
 * @returns {object} - object with data and error
 */
export const getExtensionUser = async () => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.get(`/api/extension/user`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Create extension user
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const createExtensionUser = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.post(`/api/extension/createUser`, obj);
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Create user
 * @function
 * @param {object} obj - Object
 * @returns {object} - Object with data and error
 */
export const createUser = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }

  try {
    const response = await axios.post(`/api/user/create`, obj);
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Delete user by type app or extension
 * @function
 * @param {string} type - user type "app" or "extension"
 * @param {string} obj - userto be deleted
 * @returns {object} - object with data and error
 */
export const deleteUser = async (type, obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  const url =
    type === "Supervisor"
    ? "/api/user/delete"
    :"/api/extension/user/delete"

  try {
    const response = await axios.delete(url, {
      headers: { Authorization: `Bearer ${token}` },
      data:{obj}
      
    });
    return {
      data: response,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};
