import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * Get supervisor user
 * @function
 * @param {object} obj - object
 * @returns {object} - object with data and error
 */
export const getSupervisorUser = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.post(`/api/supervisor`, {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data.message,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Remove user
 * @function
 * @param {object} obj - object
 * @returns {object} - object with data and error
 */
export const removeUser = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.delete(`/api/supervisor/remove/user`, {
      headers: { Authorization: `Bearer ${token}` },
      data:{obj}
    });
    return {
      data: response,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};

/**
 * Add user
 * @function
 * @param {object} obj - object
 * @returns {object} - object with data and error
 */
export const addUser = async (obj) => {
  const token = getToken();
  if (!token) {
    return {
      data: null,
      err: Error("Token not found"),
    };
  }
  try {
    const response = await axios.post(`/api/supervisor/create`, {
      headers: { Authorization: `Bearer ${token}` },
      obj,
    });
    return {
      data: response.data,
      err: null,
    };
  } catch (error) {
    return {
      data: null,
      err: error,
    };
  }
};
