import { Box, Grid, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import "../../../assets/css/App.css";
import SpinnerCircle from "Utils/SpinnerCircle";
import React from "react";
import Chart from "react-apexcharts";
import { durationChartData } from "../utils/ChartDefaultData";
import { secondsToHms } from "Utils/Common";


export const AppEventsCharts = ({
  state,
  stateCategory,
  stateDuration,
  stateTtitle,
  appTime,
  loadingData,
}) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack spacing={3}>
        <Box
          className="appEventstyle"
          style={{
            overflow: "hidden",
            gridTemplateColumns: loadingData && "none",
            padding:loadingData && "10px",
          }}
        >
          {loadingData ? (
            <Box textAlign={"center"} my={"10px"}>
              <SpinnerCircle />
            </Box>
          ) : (
            <SimpleGrid
              columns={{ base: 1, md: 2, "3xl": 3 }}
              mt={"5px"}
              w="100%"
              mx={"auto"}
              mb={"10px"}
              boxSizing="border-box"
            >
              <Grid item xs={4}>
                <Box>
                  <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
                    App Events
                  </Text>
                  {state.series[0].data.length > 0 && (
                    <Chart
                      className="appEventBoxFillColor"
                      options={state.options}
                      series={state.series}
                      type="bar"
                      width={500}
                    />
                  )}
                </Box>
              </Grid>
              <Grid item xs={4}>
                <div>
                  <Box
                    size="md"
                    pl={"30px"}
                    pt={"10px"}
                    width={"16rem"}
                    fontSize={"20px"}
                  >
                    Active Time :
                    <Text style={{ fontSize: "14px" }}>
                      {secondsToHms(appTime)}
                    </Text>
                  </Box>
                  {stateDuration.length > 0 && (
                    <Chart
                      className="appEventBoxFillColor"
                      options={durationChartData.options}
                      series={stateDuration}
                      type="radialBar"
                      width={380}
                    />
                  )}
                </div>
                {secondsToHms(appTime) && (
                  <Box
                    pl={"30px"}
                    pt={"10px"}
                    width={"16rem"}
                    fontSize={"20px"}
                    mb={"10px"}
                  >
                    [
                    <span
                      style={{
                        fontSize: "14px",
                        margin: " 0 5px",
                      }}
                    >
                      Active Time is calculated by "Total Activity Duration" / 9
                      (hours)
                    </span>
                    ]
                  </Box>
                )}
              </Grid>
              <Grid item xs={4}>
                <Box>
                  <Text pl={"30px"} py={"10px"} fontSize={"20px"}>
                    Category Events
                  </Text>
                  {stateCategory.series[0].data.length > 0 && (
                    <Chart
                      className="appEventBoxFillColor"
                      options={stateCategory.options}
                      series={stateCategory.series}
                      type="bar"
                      width={500}
                    />
                  )}
                </Box>
              </Grid>
            </SimpleGrid>
          )}
        </Box>
        <Grid
          xs={12}
          className="ApptitleStyle"
          my={"auto"}
          w={"100%"}
          mb={"18px"}
          pb={"20px"}
        >
          {loadingData ? (
            <Box textAlign="center" my="18px">
              <SpinnerCircle />
            </Box>
          ) : (
            <>
              <Text pl="30px" py="10px" fontSize="20px">
                App Title
              </Text>
              <Box h="14rem" overflowY="scroll">
                <Box>
                  {state.series[0].data.length > 0 && (
                    <Chart
                      className="appEventBoxFillColor"
                      options={stateTtitle.options}
                      series={stateTtitle.series}
                      type="bar"
                      width="100%"
                      height={state.series[0].data.length * 300}
                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Grid>
      </Stack>
    </Box>
  );
};
