import React, { useEffect, useState } from "react";
import {
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { removeUserSession } from "Utils/Common";
import SpinnerCircle from "Utils/SpinnerCircle";
import Card from "components/card/Card";
import SearchUser from "components/manageUser/SearchUser";
import Swal from "sweetalert2";
import { MdRestoreFromTrash } from "react-icons/md";
import SelectSupervisor from "./SelectSupervisor";
import AssignUsers from "./AssignUsersModal";
import { getSupervisorUser, removeUser as removeSupervisorUser } from "apis/supervisor";
import ReactPaginate from "react-paginate";

export default function SupervisorList({ reload, setReload }) {
  const {colorMode}=useColorMode()
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [users, setUsers] = useState([]);
  const [spinnerState, setSpinnerState] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [supervisorOption, setSupervisorOption] = useState("");

  useEffect(() => {
    async function getSuperVisorUser() {
      setSpinnerState(true);
      try {
        let obj = {};
       if (supervisorOption) {
          obj["supervisorEmail"] = supervisorOption;
        }

        const { data, err } = await getSupervisorUser(obj);
        if (!err) {
          setUsers(data);
        } else {
          console.error("error fetching supervisor user:", err);
          removeUserSession();
        }
      } catch (error) {
        console.error("Unexpected error:", error);
      }
      setSpinnerState(false);
    }

    getSuperVisorUser();
  }, [supervisorOption, reload]);

  const handleClickRemove = async (user, setReload) => {
    try {
      if (supervisorOption) {
        const { data, err } = await removeSupervisorUser({
          supervisorEmail: supervisorOption,
          userEmail: user.useremail,
        });

        if (!err) {
          Swal.fire({ text: "Removed", confirmButtonColor: "#ff9d00" });
          setReload(data);
        } else {
          Swal.fire({
            text: "Something went wrong",
            confirmButtonColor: "#ff9d00",
          });
          console.log("/api/supervisor/remove/user cannot get data");
        }
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };

  const searchedItems =
    searchValue.length > 0
      ? users.filter((item) =>
          item.name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : users;

  const itemsPerPage = 9;
  const pageCount = Math.ceil(searchedItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = searchedItems.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      backgroundColor={colorMode==='light'?"":"#101c44"}
    >
      <Flex
        align="center"
        w="100%"
        px="20px"
        py="10px"
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        gap={"10px"}
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Supervisors
        </Text>
        <Flex gap="5px">
          <SearchUser
            width="40%"
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <SelectSupervisor
            supervisorOption={supervisorOption}
            setSupervisorOption={setSupervisorOption}
          />
          <AssignUsers
            supervisorOption={supervisorOption}
            setSupervisorOption={setSupervisorOption}
            setReload={setReload}
            reload={reload}
          />
        </Flex>
      </Flex>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                User
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                UserEmail
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Supervisor
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {spinnerState ? (
              <Td colSpan={3} textAlign={"center"}>
                <SpinnerCircle />
              </Td>
            ) : users.length !== 0 ? (
              currentData.map((row, index) => (
                <Tr key={index}>
                  <Td component="th" scope="row">
                    {row.name}
                  </Td>
                  <Td component="th" scope="row">
                    {row.useremail}
                  </Td>
                  <Td align="centre">{supervisorOption}</Td>
                  <Td align="centre">
                    <IconButton
                      icon={<MdRestoreFromTrash size={"30px"} />}
                      colorScheme="red"
                      aria-label="Delete"
                      size="xs"
                      variant="ghost"
                      onClick={() => {
                        handleClickRemove(row, setReload);
                      }}
                    />
                  </Td>
                </Tr>
              ))
            ) : (
              <Td textAlign={"center"} colSpan={3}>
                There is no data here. First select Supervisor{" "}
              </Td>
            )}
          </Tbody>
        </Table>
        {users.length > 0 && (
          <Flex
            style={{
              display: "flex",
              justifyContent: "flex-end",
              paddingTop: "5px",
              paddingRight: "5px",
            }}
          >
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              previousLabel={"<"}
              nextLabel={">"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </Flex>
        )}
      </TableContainer>
    </Card>
  );
}
