import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  IconButton,
  TableContainer,
  useColorMode,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import Card from "components/card/Card";
import SpinnerCircle from "../../Utils/SpinnerCircle";
import Swal from "sweetalert2";
import FilterUsers from "./FilterUsers";
import SearchUser from "./SearchUser";
import "./Pagination.css";
import { MdRestoreFromTrash } from "react-icons/md";
import { removeUserSession } from "Utils/Common";
import { AddUserModal } from "./AddUserModal";
import { deleteUser } from "apis/user";
import { getExtensionUser } from "apis/user";
import { getUsers } from "apis/user";
import { getUser } from "Utils/Common";

export default function UsersList({ setReload, reload }) {
  const {colorMode}=useColorMode()
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [spinnerState, setSpinnerState] = useState(false);
  const [users, setUsers] = useState([]);
  const [filterUsers, setFilterUsers] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    async function fetchData() {
      setSpinnerState(true);
      try {
        const { data: userData, err: userErr } = await getUsers()
        const { data: extensionData, err: extensionErr } =
          await getExtensionUser();
          if (!userErr && !extensionErr) {
            const adminUser = getUser();
            const admin = userData.filter((user) => user.email === adminUser.email);
          
            if (admin.length === 1) { 
              // remove admin from user Data  
              const userDataWithoutAdmin = userData.filter((user) => user.email !== adminUser.email);

              const supervisorUsers = mapUsers(userDataWithoutAdmin, "Supervisor");
              const extensionUsers = mapUsers(extensionData, "User");
              const isAdminUser = mapUsers(admin, "Admin");
              const allUsers = [ ...isAdminUser,...supervisorUsers, ...extensionUsers ];
              setUsers(allUsers);
            } else {
              console.error("Invalid number of admin users found:", admin.length);
              removeUserSession();
            }
          } else {
            console.error("Error fetching users:", userErr || extensionErr);
            removeUserSession();
          }
      } catch (error) {
        console.error("Unexpected error:", error);
      }
      setSpinnerState(false);
    }

    fetchData();
  }, [reload,setReload]);
  const mapUsers = (data, userType) => {
    return data.length > 0
      ? data.map((item) => ({
          type: userType,
          email: item.email,
          lastname: item.lastname,
          firstname: item.firstname,
        }))
      : [];
  };
  const handleUserDelete = async (row, setReload) => {
    const type = row.type;
    try {
      const { data, err } = await deleteUser(type, { email: row.email });

      if (!err) {
        Swal.fire({
          text: `${type === "Supervisor" ? "Supervisor" : "User"} Deleted`,
        });
        setReload(data);
      } else {
        Swal.fire({
          text: "Something went Wrong",
        });
        console.log("/api/user/remove/user cannot get data", err);
      }
    } catch (error) {
      console.error("Unexpected error:", error);
    }
  };
  let filteredItems =
    filterUsers === "All"
      ? users
      : users.filter((user) => user.type === filterUsers);

  const searchedItems =
    searchValue.length > 0
      ? filteredItems.filter((item) =>
          item.firstname.toLowerCase().includes(searchValue.toLowerCase())
        )
      : filteredItems;

  const itemsPerPage = 9;
  const pageCount = Math.ceil(searchedItems.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = searchedItems.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
      backgroundColor={colorMode==='light'?"":"#101c44"}
    >
      <Flex
        align="center"
        w="100%"
        px="20px"
        py="10px"
        justifyContent={"space-between"}
        flexDirection={{ base: "column", md: "row" }}
        gap={"10px"}
        alignItems={{ base: "flex-start", md: "center" }}
      >
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Users
        </Text>
        <Flex gap="5px">
          <SearchUser
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            width="40%"
          />
          <FilterUsers filter={filterUsers} setFilter={setFilterUsers} />
          <AddUserModal setReload={setReload} />
        </Flex>
      </Flex>
      <TableContainer>
        <Table variant="simple" color="gray.500" colorScheme='gray'>
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                Email
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                FirstName
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                LastName
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Type
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Action
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {spinnerState ? (
              <Td colSpan={5} textAlign={"center"}>
                <SpinnerCircle />
              </Td>
            ) : (
              currentData.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td>{row.email}</Td>
                    <Td>{row.firstname}</Td>
                    <Td>{row.lastname}</Td>
                    <Td>{row.type}</Td>
                    <Td>
                      <IconButton
                        icon={<MdRestoreFromTrash size={"30px"} />}
                        colorScheme="red"
                        aria-label="Delete"
                        size="xs"
                        variant="ghost"
                        onClick={() => handleUserDelete(row, setReload)}
                      />
                    </Td>
                  </Tr>
                );
              })
            )}
          </Tbody>
        </Table>
        {currentData.length > 0 && (
          <Flex
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
              padding: "5px",
            }}
          >
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={6}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              previousLabel={"<"}
              nextLabel={">"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </Flex>
        )}
      </TableContainer>
    </Card>
  );
}
