import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import SpinnerCircle from "Utils/SpinnerCircle";
import Card from "components/card/Card";
import moment from "moment";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { listSession } from "../../apis/session";
import { getDifferenceInMinutes } from "Utils/Common";

export const UserSessions = ({ userName, time, setUserSessions }) => {
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [spinnerState, setSpinnerState] = useState(false);
  const [session, setSession] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    if (userName && time.start && time.end) {
      setSpinnerState(true);
      (async () => {
        const { data, err } = await listSession({
          email: userName,
          time: {
            start: moment(time.start).utc().format(),
            end: moment(time.end).utc().format(),
          },
        });
        if (!err) {
          setSession(data);
          setUserSessions(data);
        }
        setSpinnerState(false);
      })();
    }
    //eslint-disable-next-line
  }, [userName, time]);

  const itemsPerPage = 6;
  const pageCount = Math.ceil(session.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = session.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <Card w="100%" p="10px">
      <Flex w="100%" direction="column" h="90%">
        <Flex align="center" px="20px" py="10px">
          <Text
            color={textColor}
            fontSize="22px"
            fontWeight="700"
            lineHeight="100%"
          >
            User Sessions
            {/* User Sessions - {session.length} */}
          </Text>
        </Flex>
        <TableContainer h="90%">
          <Table variant="striped" colorScheme="gray">
            <Thead>
              <Tr>
                <Th pe="10px" borderColor={borderColor}>
                  Start
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  End
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  Time
                </Th>
                <Th pe="10px" borderColor={borderColor}>
                  IP
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {spinnerState ? (
                <Tr>
                  <Td colSpan={4} textAlign={"center"}>
                    <SpinnerCircle />
                  </Td>
                </Tr>
              ) : (
                session.length > 0 &&
                currentData.map((row, index) => {
                  return (
                    <Tr key={index}>
                      <Td fontSize={"sm"}>
                        {new Date(row.start_session).toString()}
                      </Td>
                      <Td fontSize={"sm"}>
                        {row.end_session !== row.start_session
                          ? new Date(row.end_session).toString()
                          : "not ended"}
                      </Td>
                      <Td fontSize={"sm"}>
                        {getDifferenceInMinutes(
                          new Date(row.start_session),
                          new Date(row.end_session)
                        )}
                      </Td>
                      <Td fontSize={"sm"}>
                        {row.ipaddress ? row.ipaddress : "old"}
                      </Td>
                    </Tr>
                  );
                })
              )}
            </Tbody>
          </Table>
        </TableContainer>
      </Flex>
      {currentData.length > 0 && (
        <Flex
          justifyContent="flex-end"
          py="5px"
          alignItems="flex-end"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            previousLabel={"<"}
            nextLabel={">"}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        </Flex>
      )}
    </Card>
  );
};
