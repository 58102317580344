import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useState } from "react";
import SupervisorList from "./SupervisorList";

export default function SupervisorsDashborad() {
  const [reload, setReload] = useState("");

  return (
    <Box pt={{ base: "130", md: "80px", xl: "80px" }}>
      <SimpleGrid column={{ base: 1 }} gap="20px" mb="20px">
        <SupervisorList reload={reload} setReload={setReload} />
      </SimpleGrid>
    </Box>
  );
}
