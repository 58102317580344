import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  useDisclosure,
  Tbody,
  Td,
  Tr,
  Checkbox,
} from "@chakra-ui/react";
import { removeUserSession } from "Utils/Common";
import SpinnerCircle from "Utils/SpinnerCircle";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import "./CustomCssSwal.css";
import { getUsers } from "apis/user";
import { getExtensionUser } from "apis/user";
import { getSupervisorUser } from "apis/supervisor";
import { addUser } from "apis/supervisor";
import { removeUser } from "apis/supervisor";

export default function AssignUsers({
  supervisorOption,
  setSupervisorOption,
  reload,
  setReload,
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [users, setUsers] = useState([]);
  const [supervisors, setSupervisors] = useState([]);
  const [supervisorEmail, setSupervisorEmail] = useState(
    supervisorOption ?? ""
  );
  const [userPermission, setUserPermission] = useState([]);
  const [spinnerState, setSpinnerState] = useState(false);
  const [removeUserArr, setRemoveUserArr] = useState([]);
  const [listSendUser, setListSendUser] = useState([]);

  useEffect(() => {

    async function fetchUsers() {
      try {
        const [supervisorRes, usersRes] = await Promise.all([
          getUsers(),
          getExtensionUser(),
        ]);

        if (usersRes.data) {
          const filterArr = usersRes.data.map((item) => ({
            ...item,
            useremail: item.email,
          }));

          setUsers(filterArr);
        }

        setSupervisors(supervisorRes.data);
      } catch (error) {
        removeUserSession();
      }
    }

    fetchUsers();
  }, [reload]);

  useEffect(() => {
    if (supervisorOption.length > 0) {
      setSupervisorEmail(supervisorOption);
    }
    const emailToFetch = supervisorEmail || supervisorOption;
    async function getSuperVisorUser() {
      let obj = {
        supervisorEmail: emailToFetch,
      };

      const { data, err } = await getSupervisorUser(obj);
      if (!err) {
        if (data) {
          const filterArr = data.map((item) => ({
            ...item,
            email: item.useremail,
          }));
          setUserPermission(filterArr);
        }
        setSpinnerState(false);
      } else {
        removeUserSession();
      }
    }

    getSuperVisorUser();
  }, [supervisorOption, supervisorEmail]);

  //handle select tag value change
  const handleSelectChange = (e) => {
    setSpinnerState(true);
    setSupervisorOption(e.target.value);
    setSupervisorEmail(e.target.value);
    setSpinnerState(false);
  };

  //handle checkBox
  const handleCheckBox = (event, row) => {
    const userEmail = row.email;

    if (event.target.checked) {
      setUserPermission((prevArr) => [...prevArr, row]);
      setListSendUser((prevList) => [...prevList, row]);
      setRemoveUserArr((prevArr) =>
        prevArr.filter((item) => item.useremail !== userEmail)
      );
    } else {
      setUserPermission((prevArr) =>
        prevArr.filter((item) => item.useremail !== userEmail)
      );
      setListSendUser((prevList) =>
        prevList.filter((item) => item.useremail !== userEmail)
      );
      setRemoveUserArr((prevArr) => [...prevArr, { useremail: userEmail }]);
    }
  };

  //handle confirm btn
  const handleSubmitOnConfirm = (e) => {
    e.preventDefault();

    if (supervisorEmail) {
      if (listSendUser.length > 0) {
        const uniqueUsers = Array.from(
          new Set(listSendUser.map((a) => a.email))
        ).map((email) => {
          return listSendUser.find((a) => a.email === email);
        });

        uniqueUsers.forEach((item) => {
          handleAddUser( supervisorEmail, item.email);
        });
      }

      if (removeUserArr.length > 0) {
        removeUserArr.forEach((item) => {
          handleClickRemove(supervisorEmail, item.useremail);
        });
      }
    }
    setRemoveUserArr([]);
    setListSendUser([]);
  };

  //Add user
  const handleAddUser =async ( supervisorEmail, userEmail) => {
    if (supervisorEmail  && userEmail) {
      try {
        const response = await addUser({
          supervisorEmail: supervisorEmail,
          userEmail: userEmail,
        })
    
        Swal.fire({
          text: "User Saved",
          confirmButtonColor: "#ff9d00",
          customClass: {
            container: "swal2-popup",
          },
        });
        setReload(response)
        return response.data;
      } catch (error) {
        Swal.fire({
          text: "Something went Wrong",
          confirmButtonColor: "#ff9d00",
          customClass: {
            container: "swal2-popup",
          },
        });
    
        console.error("error adding user:", error);
        throw error; 
      }
  }
  };

// handle remove 
  const handleClickRemove = async (supervisorEmail, userEmail) => {
    try {
      if (supervisorEmail) {
        const { data, err } = await removeUser({
          supervisorEmail: supervisorOption,
          userEmail: userEmail,
        });

        if (!err) {
          Swal.fire({ text: "User Was Removed", confirmButtonColor: "#ff9d00",customClass: {
            container: "swal2-popup",
          }, });
          setReload(data);
        } else {
          Swal.fire({
            text: "Something went wrong",
            confirmButtonColor: "#ff9d00",
            customClass: {
                          container: "swal2-popup",
                        },
          });
          console.log("/api/supervisor/remove/user cannot get data");
        }
      }
    } catch (error) {
      console.error("unexpected error:", error);
    }
  };


  //handle default checkBox
  const handleDefault = (row) => {
    const arr = [];
    userPermission.map(
      (userItem) =>
        userItem.useremail === row.email && arr.push(userItem.useremail)
    );
    return arr.length > 0 ? arr : [];
  };

  //handle checked
  const isChecked = (userEmail) =>
    userPermission.some((item) => item.useremail === userEmail);

  return (
    <>
      <Button colorScheme="blue" variant="outline" onClick={onOpen}>
        Assign Users
      </Button>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="2xl"
      >
        <ModalOverlay>
          <ModalContent maxH={"82vh"} overflow="hidden">
            <ModalHeader>
              Select Supervisor to assign user
              <ModalCloseButton />
            </ModalHeader>
            <ModalBody overflow={"hidden"}>
              <Flex
                align="center"
                w="100%"
                gap={"10px"}
                justifyContent={"space-between"}
              >
                <FormControl variant="outlined">
                  <Select
                    borderColor="#2b6cb0"
                    borderRadius="15px"
                    color="#2b6cb0"
                    fontWeight="bold"
                    id="selectValue"
                    onChange={handleSelectChange}
                    value={supervisorOption}
                  >
                    <option value="" disabled selected>
                      -- Select Supervisor --{" "}
                    </option>
                    {supervisors &&
                      supervisors.map((item, index) => {
                        return (
                          <option key={index} value={item.email}>
                            {`${item.firstname}  ${item.lastname}`}
                          </option>
                        );
                      })}
                  </Select>
                </FormControl>
                <Flex align={"end"} justifyContent={"space-around"}>
                  <Button
                    colorScheme="blue"
                    variant="outline"
                    onClick={(e) => {
                      handleSubmitOnConfirm(e);
                    }}
                  >
                    Confirm
                  </Button>
                </Flex>
              </Flex>

              {spinnerState ? (
                <SpinnerCircle />
              ) : (
                <Flex maxH={"82vh"} overflowY="scroll" paddingBottom={"28"}>
                  <Table>
                    <Tbody>
                      {users.map((row, index) => (
                        <Tr key={index}>
                          <Td>
                            <Checkbox
                              value={row.email}
                              defaultValue={() => handleDefault(row)}
                              isChecked={isChecked(row.email)}
                              onChange={(e) =>
                                handleCheckBox(e, row, setReload, row.email)
                              }
                            />
                          </Td>
                          <Td align="center">{row.firstname}</Td>
                          <Td align="center">{row.lastname}</Td>
                          <Td component="th" scope="row">
                            {row.email}
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              )}
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </>
  );
}
