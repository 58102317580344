import {
  useColorModeValue,
  Tbody,
  Tr,
  Td,
  Thead,
  Th,
  TableContainer,
  Table,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  // ModalFooter,
  useDisclosure,
  // Link,
  Flex,
  Text,
  Box,
  OrderedList,
  ListItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import SpinnerCircle from "../../Utils/SpinnerCircle";
import moment from "moment/moment";
import ReactPaginate from "react-paginate";
import { fetchUserAttendance, fetchUserWork } from "apis/attendance";
import { toCamelCase } from "components/UserDashboard/utils/commnon";

export function AttendanceDate({ date }) {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [spinnerState, setSpinnerState] = useState(false);
  const [userAttendance, setUserAttendance] = useState([]);
  const [workData, setWorkData] = useState({
    data: [],
  });
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [workSpinner, setWorkSpinner] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentUser, setCurrentUser] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        let obj = {
          time: moment(date).utc().format("MM/DD/YYYY"),
        };
        if (date) {
          setSpinnerState(true);
          const { data, err } = await fetchUserAttendance(obj);
          if (!err) {
            setUserAttendance(data);
          } else {
            console.log("/api/attendance cannot get data", err);
            // removeUserSession();
          }

          setSpinnerState(false);
        }
      } catch (error) {
        console.error("Error fetching user attendance:", error);
      }
    };

    fetchData();
  }, [date]);

  const handleUserWork = async (selectedUser, index) => {
    try {
      if (date && selectedUser.email) {
        let obj = {
          date: moment(date).utc().format("DD-MM-YYYY"),
          user: selectedUser.email,
        };
        setWorkSpinner(true);

        const { data, err } = await fetchUserWork(obj);
        setWorkSpinner(false);

        if (!err && data.data.length > 0) {
          setCurrentUser(selectedUser.email);
          setWorkData({
            data: data.data,
          });
        } else {
          setCurrentUser("User");
          setWorkData({
            data: [],
          });
        }
      }
    } catch (error) {
      setWorkSpinner(false);
      console.error("Error fetching user work:", error);
    }
  };

  const itemsPerPage = 9;
  const pageCount = Math.ceil(userAttendance.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = userAttendance.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                Email
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Date
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Attendance
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                IP
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                Get Work
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {spinnerState ? (
              <Td colSpan={5} textAlign={"center"}>
                <SpinnerCircle />
              </Td>
            ) : (
              userAttendance.length > 0 &&
              currentData.map((row, index) => (
                <Tr key={index}>
                  <Td fontSize={"sm"}>{row.email}</Td>
                  <Td fontSize={"sm"}>
                    {new Date(row.start_session).toString()
                      ? new Date(row.start_session).toString()
                      : "old"}
                  </Td>
                  <Td fontSize={"sm"}>Present</Td>
                  <Td fontSize={"sm"}>{row.ipaddress}</Td>
                  <Td fontSize={"sm"}>
                    <Button
                      colorScheme="blue"
                      variant="solid"
                      onClick={(e) => {
                        onOpen();
                        handleUserWork(row, index);
                      }}
                      size="sm"
                    >
                      Work
                    </Button>
                  </Td>
                </Tr>
              ))
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          padding: "5px",
        }}
      >
        {currentData.length > 0 && (
          <ReactPaginate
            pageCount={pageCount}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            previousLabel={"<"}
            nextLabel={">"}
            containerClassName={"pagination"}
            activeClassName={"active"}
          />
        )}
      </Flex>

      {/* Work Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Daily Report of {toCamelCase(currentUser)}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {workSpinner ? (
                <Box display={ 'flex'} justifyContent={'center'} alignItems={'center'}>
                <SpinnerCircle />
                </Box>
              ) : workData.data.length > 0 ? (
                <OrderedList mb={"30px"} fontFamily={'sans-serif'}>
                  {workData.data.map((item, index) => (
                    <ListItem fontSize={"20px"}>{item.about_work}</ListItem>
                  ))}
                </OrderedList>
              ) : (
                <Box display={ 'flex'} justifyContent={'center'} alignItems={'center'}>
                  <Text>No Data</Text>
                </Box>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
