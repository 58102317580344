
import { getToken } from "Utils/Common";
import axios from "axios";

/**
 * Get app activity data
 * @function
 * @param {object} obj - Object 
 * @returns {object} - Object with data and error properties
 */
export const getAppActivity = async (obj) => {
    const token = getToken();
    if (!token) {
      return {
        data: null,
        err: new Error("Token not found"), 
      };
    }
  
    try {
      const response = await axios.post(`/api/activity`, {
        headers: { Authorization: `Bearer ${token}` },
        obj,
      });
  
      return {
        data: response.data.message,
        err: null,
      };
    } catch (error) {
      return {
        data: null,
        err: error,
      };
    }
  };
  
/**
 * Get app activity data
 * @function
 * @param {object} obj - Object 
 * @returns {object} - Object with data and error properties
 */
export const getTimeLineActivity = async (obj) => {
    const token = getToken();
    if (!token) {
      return {
        data: null,
        err: new Error("Token not found"), 
      };
    }
  
    try {
      const response = await axios.post(`/api/activity/timelineData`, {
        headers: { Authorization: `Bearer ${token}` },
        obj,
      });
  
      return {
        data: response.data.message,
        err: null,
      };
    } catch (error) {
      return {
        data: null,
        err: error,
      };
    }
  };