import {
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import { getUser } from "Utils/Common";
import { removeUserSession } from "Utils/Common";
import { eventAttendance } from "apis/attendance";
import { getSupervisorUser } from "apis/supervisor";
import { getExtensionUser } from "apis/user";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { MdCancel, MdDone } from "react-icons/md";
import ReactPaginate from "react-paginate";

export const AttendanceMonth = ({ dateMonth }) => {
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const [session, setSession] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (getUser().isadmin) {
          response = await getExtensionUser();
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj);
        }

        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchDataForUsers = async () => {
      for (const item of users) {
        const obj = {
          email: item.email ? item.email : item.useremail,
        };

        if (obj.email.length > 0) {
          try {
            const { data, err } = await eventAttendance(obj);
            if (!err && data.length > 0) {
              setSession((prev) => [...prev, data]);
            }
          } catch (error) {
            console.log("/api/attendance/email cannot get data");
          }
        }
      }
    };

    if (users.length > 0) {
      fetchDataForUsers();
    }
  }, [users]);

  function getDates(toDate, startDate) {
    const dateArray = [];
    let currentDate = moment(startDate);
    const stopDate = moment(toDate);

    while (currentDate <= stopDate) {
      dateArray.push(moment(currentDate).format("MM/DD/YYYY"));
      currentDate = moment(currentDate).add(1, "days");
    }

    return dateArray;
  }

  const getPresentTotal = (array, sessionOfUser) => {
    const presentDate = sessionOfUser.map((item) =>
      array.includes(item.date) ? item.date : ""
    );

    const removeEmpatyString = presentDate.filter((item) => item.length !== 0);
    const removeDuplicate = [...new Set(removeEmpatyString)];

    return removeDuplicate.length;
  };

  const tableCellOnClick = (date, sessionOfUser) => {
    let selectedSession = sessionOfUser.find((item) => item.date === date);
    if (selectedSession !== undefined) {
      // props.history.push("/adminsupervisor", selectedSession);
    }
  };

  const getPresent = (elemnt, sessionOfUser) => {
    const presentDate = sessionOfUser.some((item) => elemnt === item.date);
    return presentDate;
  };

  const itemsPerPage = 9;
  const pageCount = Math.ceil(session.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = session.slice(offset, offset + itemsPerPage);

  const handlePageClick = (selected) => {
    setCurrentPage(selected.selected);
  };

  return (
    <>
      <TableContainer>
        <Table variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th pe="10px" borderColor={borderColor}>
                Email
              </Th>
              <Th pe="10px" borderColor={borderColor}>
                total
              </Th>
              {getDates(dateMonth.stopDate, dateMonth.startDate).map(
                (item, index) => {
                  return (
                    <Th key={index + "h"} align="left">
                      {item}
                    </Th>
                  );
                }
              )}
            </Tr>
          </Thead>
          <Tbody>
            {session.length > 0 &&
              currentData.map((row, index) => {
                return (
                  <Tr key={index}>
                    <Td fontSize={"sm"}> {row[0].email}</Td>
                    <Td>
                      {getPresentTotal(
                        getDates(dateMonth.stopDate, dateMonth.startDate),
                        row
                      )}
                    </Td>
                    {getDates(dateMonth.stopDate, dateMonth.startDate)?.map(
                      (item, index) => {
                        return (
                          <Td
                            key={index + "m"}
                            align="left"
                            onClick={() => tableCellOnClick(item, row)}
                          >
                            {getPresent(item, row) ? (
                              <MdDone color="green" />
                            ) : (
                              <MdCancel color="red" />
                            )}
                          </Td>
                        );
                      }
                    )}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
        <Flex
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            padding: "5px",
          }}
        >
          {currentData.length > 0 && (
            <ReactPaginate
              pageCount={pageCount}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageClick}
              previousLabel={"<"}
              nextLabel={">"}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          )}
        </Flex>
    </>
  );
};
