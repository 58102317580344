import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { removeUserSession, getUser } from "Utils/Common";
import { fetchWorkWithRange } from "apis/attendance";
import { getSupervisorUser } from "apis/supervisor";
import { getExtensionUser } from "apis/user";
import DatePicker from "react-datepicker";
import { DownloadIcon, SmallCloseIcon, TimeIcon } from "@chakra-ui/icons";

function DownloadReportFileModal() {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [spinnerState, setSpinnerState] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedUserNames, setSelectedUserNames] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvLink, setCsvLink] = useState({});

  useEffect(() => {
    if (csvData.length > 0) {
      setTimeout(() => {
        document.getElementById("DownloadLink").click();
      }, 1000);
    }
  }, [csvData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;

        if (getUser().isadmin) {
          response = await getExtensionUser();
        } else {
          const obj = { supervisorEmail: getUser().email };
          response = await getSupervisorUser(obj);
        }

        if (!response.err) {
          setUsers(response.data);
        } else {
          removeUserSession();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleUserSelectChange = (e) => {
    const selectedUserName = e.target.options[e.target.selectedIndex].text;
    setUserName(e.target.value);
    setSelectedUserNames([...selectedUserNames, selectedUserName]);
    setSelectedUsers([...selectedUsers, e.target.value]);
  };

  const generateDownloadLink = async () => {
    const obj = {
      users: selectedUsers,
      startTime: getFormattedDate(startTime),
      endTime: getFormattedDate(endTime),
    };
    if (userName.length > 0) {
      setSpinnerState(true);
      try {
        const { data, err } = await fetchWorkWithRange(obj);

        if (!err) {
          //remove double quotes
          const sanitizedData = data.data.map((row) =>
            Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key.replace(/"/g, ""),
                typeof value === "string" ? value.replace(/"/g, "") : value,
              ])
            )
          );

          setCsvData(sanitizedData);
          setCsvLink({
            filename: getDownloadFileName(
              selectedUserNames,
              getFormattedDate(startTime),
              getFormattedDate(endTime)
            ),
            headers:
              selectedUsers.length > 1
                ? makeHeader(data.data)
                : singleUserFileHeader,
            data: sanitizedData,
          });
          toast({
            title: "File Download Successfully.",
            status: "success",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
          resetFields();
          setTimeout(() => {
            setCsvData([]);
          }, 1000);
        } else {
          toast({
            title: "Error downloading file. Please try again.",
            status: "error",
            duration: 3000,
            position: "top-right",
            isClosable: true,
          });
        }
      } catch (error) {
        toast({
          title: "Failed to fetch data for download:",
          description: `${error.message}`,
          status: "error",
          duration: 3000,
          position: "top-right",
          isClosable: true,
        });
      } finally {
        setSpinnerState(false);
      }
    }
  };

  const handleGenrateFileLink = () => {
    if (selectedUsers.length === 0 || !startTime || !endTime) {
      toast({
        title: "Please fill in all fields.",
        status: "error",
        duration: 3000,
        position: "top-right",
        isClosable: true,
      });
      return;
    }

    generateDownloadLink();
  };

  const handleModalClose = () => {
    onClose();
    resetFields();
  };

  const handleDeleteUser = (id) => {
    const updatedUsers = selectedUsers.filter((_, index) => index !== id);
    const updatedUserNames = selectedUserNames.filter(
      (_, index) => index !== id
    );
    setSelectedUsers(updatedUsers);
    setSelectedUserNames(updatedUserNames);
    if (updatedUsers.length === 0) {
      setUserName("");
    } else {
      setUserName(updatedUsers[updatedUsers.length - 1]);
    }
  };

  const resetFields = () => {
    setUserName("");
    setSelectedUserNames([]);
    setSelectedUsers([]);
  };

  return (
    <>
      <Button
        colorScheme="blue"
        variant="outline"
        onClick={onOpen}
        marginRight={"5px"}
        fontSize={"small"}
      >
        Download Report File
      </Button>

      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleModalClose}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download User Daily Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="100%">
              <form>
                <FormControl isRequired>
                  <Stack spacing={3} gap={"10px"}>
                    <Flex flexDirection={"column"} gap={"20px"}>
                      <Select
                        variant="filled"
                        value={userName}
                        onChange={handleUserSelectChange}
                        placeholder="Select user"
                      >
                        {users.map((item, index) => (
                          <option
                            key={index}
                            value={
                              getUser().isadmin ? item.email : item.useremail
                            }
                          >
                            {getUser().isadmin
                              ? `${item.firstname} ${item.lastname}`
                              : item.name}
                          </option>
                        ))}
                      </Select>
                      <Flex
                        flexWrap={"nowrap"}
                        display={selectedUserNames.length > 0 ? "flex" : "none"}
                        fontSize={"14px"}
                      >
                        {selectedUserNames.map((user, index) => (
                          <Box
                            key={index}
                            className={
                              colorMode === "light"
                                ? "datepicker-input"
                                : "custom-datepicker-input"
                            }
                            display={"flex"}
                            alignItems={"center"}
                            marginRight={"5px"}
                            padding={"2px"}
                          >
                            {user}
                            <SmallCloseIcon
                              onClick={() => handleDeleteUser(index)}
                              marginLeft={"5px"}
                            />
                          </Box>
                        ))}
                      </Flex>
                      <Flex
                        flexDirection={"row"}
                        columnGap={"20px"}
                        justifyContent={"space-between"}
                      >
                        <Box display={"flex"} gap={3}>
                          <Text>From</Text>
                          <DatePicker
                            selected={startTime}
                            onChange={(value) => setStartTime(value)}
                            showIcon={true}
                            dateFormat={"MM/dd/yyyy"}
                            className={
                              colorMode === "light"
                                ? "datepicker-input"
                                : "custom-datepicker-input"
                            }
                          />
                        </Box>
                        <Box display={"flex"} gap={3}>
                          <Text>To</Text>
                          <DatePicker
                            selected={endTime}
                            onChange={(value) => setEndTime(value)}
                            showIcon={true}
                            dateFormat={"MM/dd/yyyy"}
                            className={
                              colorMode === "light"
                                ? "datepicker-input"
                                : "custom-datepicker-input"
                            }
                          />
                        </Box>
                      </Flex>
                    </Flex>
                    <ModalFooter>
                      {spinnerState ? (
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          type="submit"
                          mr={1}
                          fontSize={"small"}
                          marginBottom={"10px"}
                        >
                          Downloading..
                          <TimeIcon marginLeft={"5px"} />
                        </Button>
                      ) : (
                        <Button
                          colorScheme="blue"
                          variant="solid"
                          type="submit"
                          mr={1}
                          fontSize={"small"}
                          marginBottom={"10px"}
                          onClick={
                            selectedUsers.length > 0
                              ? handleGenrateFileLink
                              : null
                          }
                        >
                          Download File
                          <DownloadIcon marginLeft={"5px"} />
                          {csvData.length > 0 && (
                            <CSVLink
                              id="DownloadLink"
                              {...csvLink}
                              style={{ display: "none" }}
                            ></CSVLink>
                          )}
                        </Button>
                      )}
                      <Button
                        onClose={handleModalClose}
                        fontSize={"small"}
                        marginBottom={"10px"}
                      >
                        Cancel
                      </Button>
                    </ModalFooter>
                  </Stack>
                </FormControl>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DownloadReportFileModal;

export function getFormattedDate(date) {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
}

export function getDownloadFileName(users, startDate, endDate) {
  const [Syear, Smonth, Sday] = startDate.split("-");
  const [Eyear, Emonth, Eday] = endDate.split("-");

  const formattedDateS = `${Sday}-${Smonth}-${Syear}`;
  const formattedDateE = `${Eday}-${Emonth}-${Eyear}`;

  if (users.length > 1) {
    return `Report ${formattedDateS} to ${formattedDateE}.csv`;
  } else {
    return `${users[0]} ${formattedDateS} to ${formattedDateE}.csv`;
  }
}

export const singleUserFileHeader = [
  { label: "Date", key: "text_date" },
  { label: "Work", key: "about_work" },
];

export const makeHeader = (data) => {
  const keys = Object.keys(data[0]).slice(1);
  const labels = keys.map((name) => {
    const trimmedName = name.trim();
    return { label: trimmedName, key: trimmedName };
  });
  return [{ label: "Date", key: "text_date" }, ...labels];
};
