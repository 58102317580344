import {
  Avatar,
  Box,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom Components
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
// Assets
import { routes, routesSupervisor } from "routes.js";
import ThemeEditorMode from "./ThemeEditorMode";
import { removeUserSession } from "Utils/Common";
import { useHistory } from "react-router-dom";
import { getUser } from "Utils/Common";
import { getToken } from "Utils/Common";
import { version } from "Utils/Common";

export default function HeaderLinks(props) {
  const userRoute = getToken() && getUser().isadmin ? routes : routesSupervisor;

  let history = useHistory();
  // const { secondary } = props;
  // Chakra Color Mode
  // const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const [user, setUser] = React.useState("");

  useEffect(() => {
    let user = getUser();
    if (user !== undefined) {
      setUser(user);
    }
  }, []);

  return (
    <Flex
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
      bg={menuBg}
      flexWrap={"unset"}
      p="10px"
      borderRadius="30px"
      boxShadow={shadow}
      justifyContent={{ base: "space-between" }}
    >
      <SidebarResponsive routes={userRoute} />
      <Box display={"flex"} alignItems={"center"} mr={2}>
        <ThemeEditorMode />
        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={user.email}
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px" direction={'column'}>
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {user.email}
              </Text>
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                CarbonTrack v{version}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  removeUserSession();
                  history.push("/auth/sign-in");
                }}
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Box>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
