import { Select } from "@chakra-ui/react";

export default function FilterUsers ({ filter, setFilter }){
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  };

  return (
    <Select width="30%" value={filter} onChange={handleFilterChange}  borderColor="#2b6cb0" borderRadius="15px" color="#2b6cb0" fontWeight="bold"> 
      <option value="All">All</option>
      <option value="Admin">Admin</option>
      <option value="User">Users</option>
      <option value="Supervisor">Supervisors</option>
    </Select>
  );
};

