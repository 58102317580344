import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  useDisclosure,
  Flex,
  FormControl,
  Input,
  Box,
  Stack,
  InputGroup,
  InputRightElement,
  Select,
  useToast,
} from "@chakra-ui/react";

import sha512 from "js-sha512";

import { getToken } from "Utils/Common";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { createExtensionUser } from "apis/user";
import { createUser } from "apis/user";

export function AddUserModal({ setReload }) {
  const toast=useToast()
  const { isOpen, onOpen, onClose } = useDisclosure();

  const usertype = [
    { label: "User", value: "User" },
    { label: "Supervisor", value: "Supervisor" },
  ];
  const [showPassword, setShowPassword] = useState(false);
  const handlePasswordClick = () => setShowPassword(!showPassword);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    selecteduserType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    try {
      const token = getToken();
      const hashPassword = sha512(formData.password);
      const userform = {
        firstName: formData.firstName,
        lastName: formData.lastName,
        email: formData.email,
        password: hashPassword,
        headers: { Authorization: `Bearer ${token}` },
      };
  
      let createFunction;
  
      if (formData.selecteduserType === "User") {
        createFunction = createExtensionUser;
      } else if (formData.selecteduserType === "Supervisor") {
        createFunction = createUser;
      } else {
        console.error("wrong userType:", formData.selecteduserType);
        return;
      }
  
      const { data, err } = await createFunction(userform);
  
      if (!err) {
        toast({
          title: `${data.message}`,
          status: 'success',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          selecteduserType: "",
        });
        setReload(data);
      } else {
        toast({
          title: `${err.data}`,
          status: 'error',
          duration: 3000,
          position: 'top-right',
          isClosable: true,
        })
      }

    } catch (error) {
      console.error("unexpected error", error);
    }
  };
  
  return (
    <>
      <Button colorScheme="blue" variant="outline" onClick={onOpen}>
        Add User
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box w="100%">
              <form onSubmit={handleSubmit}>
              <FormControl isRequired>
                  <Stack spacing={3}>
                    <Flex flexDirection={"row"} columnGap={"20px"}>
                      <Input
                        variant="filled"
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                      />
                      <Input
                        variant="filled"
                        placeholder="Last Name"
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                      />
                    </Flex>
                    <Input
                      variant="filled"
                      placeholder="Email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                    <InputGroup size="md">
                      <Input
                        variant="filled"
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                      />
                      <InputRightElement width="4.5rem">
                        <Button
                          h="1.75rem"
                          size="sm"
                          onClick={handlePasswordClick}
                        >
                          {showPassword ? (
                            <RiEyeCloseLine />
                          ) : (
                            <MdOutlineRemoveRedEye />
                          )}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <Select
                      variant="filled"
                      value={formData.selecteduserType}
                      placeholder="Select User"
                      name="selecteduserType"
                      onChange={handleChange}
                    >
                      {usertype &&
                        usertype.map((item, index) => (
                          <option key={index}>{item.value}</option>
                        ))}
                    </Select>
                    <ModalFooter>
                      <Button
                        colorScheme="blue"
                        variant="solid"
                        type="submit"
                        mr={1}
                      >
                        Save
                      </Button>
                      <Button onClick={onClose}>Cancel</Button>
                    </ModalFooter>
                  </Stack>
                </FormControl>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
