import { Box, SimpleGrid } from "@chakra-ui/react";
import React, { useState } from "react";
// import AddUser from "./AddUser";
import UsersList from "./UsersList";
// import { AddUserModal } from "./AddUserModal";

export default function UserDashboard() {
  // Reload user list when create and delete user.
  const [reload, setReload] = useState("");


  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid columns={{ base: 1 }} gap="20px" mb="20px">
        {/* <SimpleGrid columns={{ base: 1 }} gap="20px" mb="20px"> */}
        {/* <AddUserModal setRelaod={setRelaod} /> */}
        {/* </SimpleGrid> */}
        <UsersList reload={reload} setReload={setReload} />
      </SimpleGrid>
    </Box>
  );
}
